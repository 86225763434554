import { gql } from '@apollo/client';

export const GET_DOCUMENTS = gql`
  query getAllDocuments(
    $allDocumentsFilter: DocumentFilter
    $page: Int
    $perPage: Int
    $sortField: AllDocumentsSortFieldEnum
    $sortOrder: SortOrderEnum
  ) {
    allDocuments(
      filter: $allDocumentsFilter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      documents {
        id
        employeeId
        title
        documentId
        workCenterId
        workCenterName
        rfc
        type
        fullName
        profilePicture
        signDate
        type
        queueStatus
        signed
        canBeSigned
        hideAfterSigning
        timestamps {
          createdAt
          updatedAt
        }
        constantType
        category
        subcategory
        fileExtension
        isAbsenceDocument
        signatures {
          signBy
          signAt
        }
        signers {
          employeeId
          rfc
          type
          isSigned
          seen
          fullName
          profilePicture
        }
        createdBy {
          _id
          rfc
          curp
          names
          lastNameP
          lastNameM
          profilePicture
        }
        signersConfig {
          signInOrder
          employeeSignsFirst
          companySignersSignInOrder
          currentSigner
        }
        user {
          _id
          names
          lastNameP
          lastNameM
          rfc
          curp
          workTitle
          payrollId
        }
        company {
          _id
          name
          clientName
        }
        workCenter {
          _id
          name
          code
        }
      }
      metadata {
        all
        unsigned
        waitingMySignature
        waitingCompSigners
        waitingEmployees
        signed
        signedByMe
        unsignable
        onboarding
      }
    }
  }
`;

export const UDPATE_EMPLOYEERECORD_TYPE = gql`
  mutation UpdateEmployeeRecordType($input: UpdateEmployeeRecordData!) {
    updateEmployeeRecordType(input: $input) {
      ... on Success {
        message
      }
      ... on DocumentTypeUnchangeable {
        message
      }
      ... on BelongToManyUsers {
        message
      }
    }
  }
`;

export const GET_RECEIPTS = gql`
  query getAllReceipts(
    $allReceiptsFilter: ReceiptFilter
    $page: Int
    $perPage: Int
    $sortField: AllReceiptsSortFieldEnum
    $sortOrder: SortOrderEnum
  ) {
    allReceipts(
      filter: $allReceiptsFilter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      id
      employeeId
      workCenterId
      workCenterName
      rfc
      fullName
      payrollId
      signDate
      queueStatus
      type
      linkUuid
      fiscalDetails {
        paymentPeriodicity
      }
      receiptDetail {
        stampUUID
        paymentStartDate
        paymentEndDate
      }
      timestamps {
        createdAt
      }
      employeeSignature {
        employeeId
      }
    }
  }
`;

/**
 * @deprecated
 */
export const CREATE_DOCUMENT = gql`
  mutation CreateDocumentMutation(
    $firstImage: Upload
    $secondImage: Upload
    $input: CreateDocumentInput
  ) {
    createDocument(
      firstImage: $firstImage
      secondImage: $secondImage
      input: $input
    ) {
      ... on FinalDocumentResponse {
        __typename
        errors {
          type
          success
          message
        }
        documents {
          id
          employeeId
          previousId
          type
          body
          title
          workCenterId
          fullName
          payrollId
          rfc
          employeeSignature {
            employeeId
          }
          url
        }
      }
    }
  }
`;

export const CREATE_SCHEDULED_DOCUMENT = gql`
  mutation CreateScheduledDocumentMutation(
    $input: CreateScheduledDocumentInput!
  ) {
    CreateScheduledDocument(input: $input) {
      ... on ErrorToCreateScheduleDocument {
        message
      }
      ... on CreateScheduledDocumentResponse {
        success
        message
        documentsCreated
      }
      ... on Success {
        message
      }
      ... on UserNotFound {
        message
      }
    }
  }
`;

export const UPLOAD_RECEIPTS = gql`
  mutation UploadReceipts($file: Upload!) {
    uploadReceipts(file: $file) {
      receiptValidation {
        employeeRfc
        path
        employeeExists
        companyDataMatches
        errorMessage
        filename
      }
      requestKey
      requestStamps
      wasThereErrors
      csvFileUrl
    }
  }
`;

export const RECEIPT_CONFIG = gql`
  mutation setReceiptConfigs($input: ReceiptConfigInput!) {
    setReceiptConfigs(input: $input) {
      showXMLSalary {
        active
      }
      showRegisteredSalary {
        active
      }
      areReceiptsSignable {
        active
      }
    }
  }
`;

export const RECEIPT_COUNT = gql`
  query receiptCount($filter: ReceiptFilter) {
    _allReceiptsMeta(filter: $filter) {
      all
      signed
      unsigned
      unsignable
    }
  }
`;

export const CONFIRM_RECEIPTS = gql`
  mutation confirmReceiptImport(
    $requestKey: String!
    $stampList: [String]!
    $xmlOnly: Boolean!
  ) {
    confirmReceiptImport(
      requestKey: $requestKey
      stampList: $stampList
      xmlOnly: $xmlOnly
    ) {
      csvFileUrl
      failed {
        errorMessage
        stampUUID
        employeeRfc
      }
      uploaded {
        id
        employeeId
        workCenterId
        rfc
        receiptDetail {
          stampUUID
        }
      }
    }
  }
`;

export const DOCUMENT_COUNT = gql`
  query documentCount(
    $allDocumentsFilter: DocumentFilter
    $page: Int
    $perPage: Int
    $sortField: AllDocumentsSortFieldEnum
    $sortOrder: SortOrderEnum
  ) {
    allDocuments(
      filter: $allDocumentsFilter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      metadata {
        all
        unsigned
        waitingMySignature
        waitingCompSigners
        waitingEmployees
        signed
        signedByMe
        unsignable
        onboarding
      }
    }
  }
`;

export const UPLOAD_SALARY = gql`
  mutation uploadSalary($file: Upload!) {
    uploadSalary(file: $file) {
      __typename
      ... on UploadSalaryWsNotFound {
        rfcs
      }
      ... on UploadSalaryUsersNotFound {
        rfcs
      }
    }
  }
`;

export const ALL_WORKCENTERS = gql`
  query getWorkcenters {
    allWorkCenters {
      id
      name
      code
    }
  }
`;

export const GET_COMPANIES = gql`
  query getCompanies {
    getMyCompanies {
      _id
      configs {
        showXMLSalary {
          active
        }
        showRegisteredSalary {
          active
        }
        blockBajaEmployees {
          active
        }
      }
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation UploadDocumentsMutation(
    $files: [Upload]
    $input: DocumentBulkUploadData
  ) {
    uploadDocuments(files: $files, input: $input) {
      ... on FinalDocumentResponse {
        __typename
        errors {
          type
          success
          message
          filename
          userError {
            names
            lastNameP
            lastNameM
            rfc
            curp
          }
        }
        documents {
          id
          workCenterId
          employeeId
          title
          body
          type
          url
          employeeSignature {
            employeeId
          }
          rfc
          fullName
          payrollId
        }
        total
        compSignersToMarkAsSeen
        compSignersToSign
      }
    }
  }
`;

export const SEND_DOCUMENT_UPLOADED_NOTIFICATION_TO_COMP_SIGNERS = gql`
  mutation sendDocumentUploadedNotificationToCompSigners(
    $compSignersToSign: [CompSignerToSendNotification]!
    $compSignersToMarkAsSeen: [CompSignerToSendNotification]!
  ) {
    sendDocumentUploadedNotificationToCompSigners(
      compSignersToSign: $compSignersToSign
      compSignersToMarkAsSeen: $compSignersToMarkAsSeen
    ) {
      __typename
    }
  }
`;

export const UPLOAD_EMPLOYEE_RECORD_DOCS = gql`
  mutation uploadEmployeeRecordDocsMutation(
    $files: [Upload]!
    $input: [UploadEmployeeRecordDocType]
  ) {
    uploadEmployeeRecordDocs(files: $files, input: $input) {
      ... on UserIsntEmployee {
        message
      }
      ... on Success {
        message
      }
    }
  }
`;

export const UPLOAD_RECEIPT_PDF = gql`
  mutation uploadReceiptPDF($file: Upload!) {
    uploadReceiptPdf(file: $file) {
      __typename
      ... on UploadReceiptPdfValidation {
        receiptValidation {
          employeeExists
          path
          employeeRfc
          pdfFound
        }
        requestKey
        requestStamps
      }
      ... on UploadReceiptPdfValidationErrors {
        errors {
          errorMessage
          filename
        }
      }
    }
  }
`;

export const CONFIRM_RECEIPT_PDF = gql`
  mutation confirmReceiptPdfImport(
    $requestKey: String!
    $stampList: [String]!
  ) {
    confirmReceiptPdfImport(requestKey: $requestKey, stampList: $stampList) {
      __typename
      ... on confirmReceiptPdfImportResponseSuccess {
        receipts {
          _id
          id
          rfc
          fullName
          workCenterId
          receiptDetail {
            stampUUID
          }
        }
      }
      ... on UploadReceiptPdfValidationErrors {
        errors {
          errorMessage
          filename
        }
      }
    }
  }
`;

export const DOWNLOAD_USER_FILE = gql`
  query downloadUserFile(
    $type: DownloadRequestTypeEnum!
    $userId: String!
    $searchId: String
    $asAttachment: Boolean
  ) {
    downloadUserFile(
      type: $type
      userId: $userId
      searchId: $searchId
      asAttachment: $asAttachment
    )
  }
`;

export const DOWNLOAD_SIGNED_XML_RECEIPT = gql`
  query DownloadSignedReceiptXmlFormat($receiptId: String) {
    downloadSignedReceiptXmlFormat(receiptId: $receiptId) {
      ... on SignedReceiptXmlFormatResult {
        url
      }
      ... on ResourceNotFound {
        message
        params
      }
      ... on ReceiptNotCompletelySigned {
        message
      }
      ... on UnsupportedReciptXmlValidation {
        message
      }
      ... on SignatureValidationFailed {
        message
      }
      ... on UnknowError {
        message
      }
      ... on InvalidXmlFormat {
        message
      }
    }
  }
`;

export const DOWNLOAD_SIGNED_XML_DOCUMENT = gql`
  query DownloadSignedDocumentXmlFormat($employeeDocumentId: String) {
    downloadSignedDocumentXmlFormat(employeeDocumentId: $employeeDocumentId) {
      ... on SignedDocumentXmlFormatResult {
        url
      }
      ... on ResourceNotFound {
        message
        params
      }
      ... on DocumentNotCompletelySigned {
        message
      }
      ... on UnsupportedDocumentXmlValidation {
        message
      }
      ... on SignatureValidationFailed {
        message
      }
      ... on UnknowError {
        message
      }
      ... on InvalidXmlFormat {
        message
      }
    }
  }
`;

export const DOWNLOAD_EMPLOYEE_RECORDS = gql`
  query DownloadEmployeeRecords($employeeId: String!) {
    downloadEmployeeRecords(employeeId: $employeeId) {
      ... on PresignedUrl {
        url
        __typename
      }
      ... on EmployeeHasntEmployeeRecords {
        message
        __typename
      }
      ... on adminHasNotPermission {
        message
        __typename
      }
    }
  }
`;

export const GET_HTML_FILE = gql`
  query getFileAsHtml(
    $type: DownloadRequestTypeEnum!
    $userId: String!
    $searchId: String
  ) {
    getFileAsHtml(type: $type, userId: $userId, searchId: $searchId)
  }
`;

export const GET_MY_FILE_AS_HTML = gql`
  query getMyFileAsHtml($type: DownloadRequestTypeEnum!, $searchId: String) {
    getMyFileAsHtml(type: $type, searchId: $searchId)
  }
`;

export const DELETE_DOCUMENTS = gql`
  mutation deleteDocuments($input: DeleteDocumentsInput!) {
    deleteDocuments(input: $input) {
      success
      data
    }
  }
`;

export const VALIDATE_XML_RECEIPTS_TO_LINK = gql`
  mutation validateXmlReceiptsToLink($file: Upload!) {
    validateXmlReceiptsToLink(file: $file) {
      fileLookupResume {
        stampUuid
        filename
        userId
        receiptDetails {
          paymentStartDate
          paymentEndDate
          paymentDate
        }
        receiptsFound {
          _id
          paymentStartDate
          paymentEndDate
        }
      }
      receiptValidation {
        employeeRfc
        path
        employeeExists
        companyDataMatches
        errorMessage
        filename
      }
      requestKey
      requestStamps
      wasThereErrors
      wasThereWarnings
    }
  }
`;
export const UPLOAD_PDFONLY_FOR_LINKING_XML = gql`
  mutation uploadPdfOnlyForLinkingXml(
    $input: uploadPdfOnlyForLinkingXmlInput!
  ) {
    uploadPdfOnlyForLinkingXml(input: $input) {
      __typename
      ... on uploadPdfOnlyForLinkingXmlSuccess {
        requestId
      }
    }
  }
`;

export const CONFIRM_XML_RECEIPTS_TO_LINK = gql`
  mutation confirmXmlReceiptsToLink($input: confirmXmlReceiptsToLinkInput!) {
    confirmXmlReceiptsToLink(input: $input) {
      __typename
    }
  }
`;

export const SET_DOCUMENT_VISIBILITY = gql`
  mutation setDocumentVisibility($documentIds: [String]!, $hide: Boolean!) {
    setDocumentVisibility(documentIds: $documentIds, hide: $hide) {
      __typename
    }
  }
`;

export const ALL_TEMPLATES_FROM_LIST = gql`
  query AllTemplates($page: Int, $perPage: Int, $filter: GetAllTemplatesInput) {
    allTemplates(page: $page, perPage: $perPage, filter: $filter) {
      ... on TemplatesResult {
        total
        templates {
          _id
          title
          description
          color {
            primary
            secondary
          }
          categories {
            category
            subcategory
          }
          timestamps {
            createdAt
            updatedAt
          }
          createdBy {
            _id
            names
            fullName
            profilePicture
          }
        }
      }
    }
  }
`;

export const ALL_TEMPLATES_FROM_TABLE = gql`
  query AllTemplates($page: Int, $perPage: Int, $filter: GetAllTemplatesInput) {
    allTemplates(page: $page, perPage: $perPage, filter: $filter) {
      ... on TemplatesResult {
        total
        templates {
          _id
          title
          description
          color {
            primary
            secondary
          }
          categories {
            category
            subcategory
          }
          timestamps {
            createdAt
            updatedAt
          }
          createdBy {
            _id
            names
            fullName
            profilePicture
          }
          status
          authorizations {
            user {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
              fullName
              profilePicture
              workSpaceStatus
            }
            status
            comments
            timestamps {
              createdAt
              updatedAt
              rejectedAt
              approvedAt
            }
          }
          history {
            action
            comments
            createdBy {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
            timestamps {
              createdAt
              updatedAt
            }
          }
          orderConfig {
            authorizeInOrder
            currentAuthorizer
          }
        }
      }
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($input: CreateTemplateInput) {
    createTemplate(input: $input) {
      ... on ErrorToCreateTemplate {
        message
      }
      ... on TemplateCreated {
        success
        message
        template {
          _id
          description
          title
          color {
            primary
            secondary
          }
          categories {
            category
            subcategory
          }
        }
      }
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($_id: String) {
    deleteTemplate(_id: $_id) {
      ... on TemplateDeleted {
        _id
        title
      }
      ... on ResourceNotFound {
        message
        params
      }
    }
  }
`;

export const GET_TEMPLATE = gql`
  query GetTemplate($_id: String) {
    getTemplate(_id: $_id) {
      ... on TemplateResult {
        _id
        title
        description
        color {
          primary
          secondary
        }
        categories {
          category
          subcategory
        }
        timestamps {
          createdAt
          updatedAt
        }
        authorizers {
          user {
            _id
            names
            lastNameP
            lastNameM
            rfc
            curp
          }
          status
          comments
        }
        createdBy
        status
        orderConfig {
          authorizeInOrder
          currentAuthorizer
        }
        htmlContent
      }
      ... on ResourceNotFound {
        message
        params
      }
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($input: UpdateTemplateInput) {
    updateTemplate(input: $input) {
      ... on ErrorToUpdateTemplate {
        message
      }
      ... on TemplateUpdated {
        success
        message
        template {
          _id
          title
          description
          htmlContent
          color {
            primary
            secondary
          }
          categories {
            category
            subcategory
          }
        }
      }
    }
  }
`;

export const GET_COMPANY_TOTAL_EMPLOYEES = gql`
  query getEmployeesCountersByStatus(
    $input: GetEmployeesCountersByStatusInput
  ) {
    getEmployeesCountersByStatus(input: $input) {
      ... on GetEmployeesCountersByStatusSuccess {
        totalCount
        filteredCount {
          total
          bajaUsers
        }
      }
    }
  }
`;

export const GET_EMPLOYEES_BY_COLLECTIVE_COUNT = gql`
  query getEmployeesByCollectiveCount(
    $input: getEmployeesByCollectiveCountInput!
  ) {
    getEmployeesByCollectiveCount(input: $input)
  }
`;

export const VERIFY_EMPLOYEE_DATA = gql`
  mutation verifyEmployeeData($input: VerifyEmployeeDataInput) {
    verifyEmployeeData(input: $input) {
      ... on ErrorToVerifyEmployeeData {
        message
      }
      ... on VerifyEmployeeDataResult {
        success
        message
        successData
        errorsData {
          userVerified {
            user {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
              fullName
              birthdate
              gender
              maritalStatus
              socialSecurityNumber
              address {
                street
                internalNumber
                externalNumber
                colony
                zipCode
                city
                state
                country
              }
            }
            workSpace {
              _id
              workTitle
              # salary
              payrollId
              workCenterId
              employmentStartDate
              workDayType
              contractType
            }
            workCenter {
              _id
              name
              code
            }
            otherData {
              salary
              age
              jobSeniority
            }
          }
          errors
          total
        }
      }
    }
  }
`;

export const DOWNLOAD_VERIFY_EMPLOYEE_DATA = gql`
  mutation downloadVerifyEmployeeData($input: DownloadVerifyEmployeeDataInput) {
    downloadVerifyEmployeeData(input: $input) {
      ... on ErrorToDownloadVerifyEmployeeData {
        message
      }
      ... on DownloadVerifyEmployeeDataResult {
        success
        links
      }
    }
  }
`;
