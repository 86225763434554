import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import { CONTACT_METHODS } from '../gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../cache.reactiveVars';
import { Button, Typography } from '../../../newComponents';

const ContactMethods = ({
  rfc,
  email,
  contactMethod,
  handleRadio,
  sendRecovery,
  handleSetIsUserIdentifierConfirmed,
  setRecoverySent,
}) => {
  const history = useHistory();
  const [contactMethodsHasBeenFound, setContactMethodsHasBeenFound] =
    useState(true);
  const [userHasContactMethods, setUserHasContactMethods] = useState(true);
  const { data, loading } = useQuery(CONTACT_METHODS, {
    variables: {
      input: {
        ...(rfc && { rfc }),
        ...(email && { email }),
      },
    },
    onCompleted(data) {
      setUserHasContactMethods(
        Boolean(data.sifeContactMethods.phone) ||
          Boolean(data.sifeContactMethods.email),
      );
      setContactMethodsHasBeenFound(
        data?.sifeContactMethods?.__typename !== 'ResourceNotFound' &&
          userHasContactMethods,
      );
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      globalBackdropVar({
        open: true,
        text: 'Enviando link de recuperación...',
      });

      const result = await sendRecovery({
        variables: {
          input: {
            contactMethod,
            ...(rfc && { rfc }),
            ...(email && { email }),
          },
        },
      });

      if (result.data?.sifeSendRecovery?.__typename === 'Success') {
        globalSnackbarVar({
          show: true,
          message: 'Link de recuperación enviado',
          severity: 'success',
        });
        setRecoverySent(true);
      } else {
        globalSnackbarVar({
          show: true,
          message: result.data?.sifeSendRecovery?.message,
          severity: 'error',
          duration: 8000,
        });
      }
    } catch (err) {
      console.error(err);
      globalSnackbarVar({
        show: true,
        message: 'No pudimos enviar tu link de recuperación',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  if (loading)
    return (
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress />
      </Stack>
    );

  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Stack
        flexDirection="column"
        alignItems="center"
        component="form"
        width="328px"
      >
        <Imagotipo height="91px" onClick={() => history.push('/login')} />
        {userHasContactMethods ? (
          <div>
            {!contactMethodsHasBeenFound ? (
              <Stack spacing={2} mt={1}>
                <p>
                  <Typography variant="body1">
                    El usuario no se ha encontrado
                  </Typography>
                </p>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSetIsUserIdentifierConfirmed(false)}
                  fullWidth
                >
                  Volver
                </Button>
              </Stack>
            ) : (
              <>
                <Stack
                  flexDirection="column"
                  width="280px"
                  alignItems="center"
                  m="50px 0"
                  spacing="24px"
                >
                  <Typography variant="h5">Recupera tu contraseña</Typography>
                  <Typography variant="body2">
                    ¿Por donde quieres ser contactado?
                  </Typography>
                  <RadioGroup
                    value={contactMethod}
                    onChange={handleRadio}
                    style={{ width: '100%' }}
                  >
                    {data?.sifeContactMethods?.email && (
                      <FormControlLabel
                        value="email"
                        control={<Radio color="primary" />}
                        label={data?.sifeContactMethods.email || ''}
                        color="secondary"
                      />
                    )}
                    {data?.sifeContactMethods?.phone && (
                      <FormControlLabel
                        value="phone"
                        control={<Radio color="primary" />}
                        label={data?.sifeContactMethods.phone || ''}
                      />
                    )}
                  </RadioGroup>
                </Stack>
                <Stack width="100%" gap={2} flexDirection="row">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleSetIsUserIdentifierConfirmed(false)}
                    fullWidth
                  >
                    Volver
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmit}
                    fullWidth
                  >
                    Continuar
                  </Button>
                </Stack>
              </>
            )}
          </div>
        ) : (
          <Stack spacing={2} mt={1}>
            <Typography variant="body1">
              El usuario no tiene métodos de contacto. Revisa con tu
              administrador
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleSetIsUserIdentifierConfirmed(false)}
              fullWidth
            >
              Volver
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default ContactMethods;
