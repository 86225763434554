import { Icon } from '../Icons/Icons';
import { sanitization } from '../../utils/validators';
import {
  TopLabel,
  DivInput,
  IconDiv,
  TextInput,
  BottomLabel,
  iconHandler,
  handleInputColor,
} from './style';
import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';

export const UserInput = ({
  placeholder = '',
  onChange,
  topLabel,
  bottomLabel,
  status,
  disabled,
  name,
  value,
}) => {
  const theme = useTheme();
  const inputColor = useMemo(() => {
    return handleInputColor(status, theme.customPalette);
  }, [status, theme]);
  const handleChange = (e) => {
    let { name, value } = e.target;
    e.target.value = sanitization[name] ? sanitization[name](value) : value;
    onChange(e);
  };

  return (
    <div style={{ textAlign: 'left', minHeight: '91px' }}>
      <TopLabel status={status}> {topLabel} </TopLabel>
      <DivInput status={status} id="container" disabled={disabled}>
        <IconDiv side="left">
          <Icon icon="user_3_line" fill={inputColor} width="24px" />
        </IconDiv>
        <TextInput
          placeholder={placeholder}
          onChange={handleChange}
          status={status}
          disabled={disabled}
          name={name}
          value={value}
        />
        <IconDiv side="right">
          <Icon
            icon={iconHandler(status, theme.customPalette).icon}
            fill={iconHandler(status, theme.customPalette).fill}
            width="24px"
          />
        </IconDiv>
      </DivInput>
      <BottomLabel status={status}> {bottomLabel} </BottomLabel>
    </div>
  );
};
