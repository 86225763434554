import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { UserInput } from '../../../components/Inputs/UserInput';
import { Imagotipo } from '../../../components/Logos/Imagotipo/Imagotipo';
import { RecoverySent } from './RecoverySent';
import { useMutation } from '@apollo/client';
import { SEND_RECOVERY } from '../gql';
import ContactMethods from './ContactMethods';
import { useHistory } from 'react-router';
import { validateInput } from '../utils';
import { Button, Typography } from '../../../newComponents';
import { emailRegex, rfcRegex } from '../../../utils/validators';

const PasswordRecovery = () => {
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [contactMethod, setContactMethod] = useState('email');
  const [recoverySent, setRecoverySent] = useState(false);
  const [recoveryRfc, setRecoveryRfc] = useState('');
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isUserIdentifierConfirmed, setIsUserIdentifierConfirmed] =
    useState(false);
  const history = useHistory();

  const [sendRecovery] = useMutation(SEND_RECOVERY);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const validation = validateInput(name, value.trim());
    if (!validation) {
      setMessage('Introduce un RFC o correo electrónico valido');
      setStatus('warning');
      return;
    }
    setMessage('');
    setStatus('success');
    setUserInput(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (emailRegex.test(userInput)) {
      setRecoveryEmail(userInput);
    }
    if (rfcRegex.test(userInput)) {
      setRecoveryRfc(userInput);
    }
    setIsUserIdentifierConfirmed(true);
    setStatus('');
  };

  const handleRadio = (e) => {
    const { value } = e.target;
    setContactMethod(value);
  };

  const handleSetIsUserIdentifierConfirmed = (value) => {
    setIsUserIdentifierConfirmed(value);
    if (value === false) {
      setRecoveryEmail('');
      setRecoveryRfc('');
    }
  };

  if (recoverySent) return <RecoverySent method={contactMethod} />;

  if (isUserIdentifierConfirmed)
    return (
      <ContactMethods
        rfc={recoveryRfc}
        email={recoveryEmail}
        handleRadio={handleRadio}
        contactMethod={contactMethod}
        sendRecovery={sendRecovery}
        handleSetIsUserIdentifierConfirmed={handleSetIsUserIdentifierConfirmed}
        setRecoverySent={setRecoverySent}
      />
    );

  return (
    <Stack
      width="100%"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Stack width="335px" component="form">
        <Box width="100%" display="flex" justifyContent="center">
          <Imagotipo height="91px" onClick={() => history.push('/login')} />
        </Box>
        <Stack
          flexDirection="column"
          width="100%"
          alignItems="center"
          m="50px 0"
          gap={2}
        >
          <Typography variant="h5">Recupera tu contraseña</Typography>
          <UserInput
            name="user"
            topLabel="Correo electrónico / RFC"
            status={status}
            bottomLabel={message}
            onChange={handleChange}
            value={undefined}
            disabled={false}
          />
        </Stack>
        <Stack flexDirection="row" gap={2} width="100%">
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => history.push('/login')}
          >
            Volver
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            fullWidth
            disabled={status !== 'success'}
          >
            Continuar
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PasswordRecovery;
